import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Grid,
  Button,
} from "@material-ui/core";
import { PlusOne, Delete } from "@material-ui/icons";
import { ScheduleType, midDay } from "@westcut/lib-common-tools";
import { daysOfTheWeek, MyTimePicker, toast } from "@westcut/lib-client-common";
import { useChangeScheduleMutation } from "../Generated/graphql";
import { useData } from "../Provider/Data";

export const Schedule: React.FC = () => {
  const [changeSchedule] = useChangeScheduleMutation({
    onCompleted: () => {
      toast("Mis à jour");
      refetch();
      setBase(JSON.stringify(getSchedule));
    },
  });
  const { operator, refetch } = useData();
  const [getSchedule, setSchedule] = React.useState<ScheduleType>(
    JSON.parse(operator.schedule),
  );
  const [getBase, setBase] = React.useState(operator.schedule);
  const stringified = JSON.stringify(getSchedule);
  return (
    <>
      {getSchedule.map((day, index) => (
        <Card key={index} style={{ margin: 10 }}>
          <CardHeader title={daysOfTheWeek[index]} />
          <CardContent>
            <Grid container spacing={2}>
              {day.map(({ from, to }, index2) => (
                <React.Fragment key={index2}>
                  <Grid item lg={2} xs={5}>
                    <MyTimePicker
                      label="De"
                      value={from}
                      onChange={(value) => {
                        const schedule = JSON.parse(
                          stringified,
                        ) as ScheduleType;
                        schedule[index][index2].from = value;
                        setSchedule(schedule);
                      }}
                    />
                  </Grid>
                  <Grid item lg={2} xs={5}>
                    <MyTimePicker
                      label="À"
                      value={to}
                      onChange={(value) => {
                        const schedule = JSON.parse(
                          stringified,
                        ) as ScheduleType;
                        schedule[index][index2].to = value;
                        setSchedule(schedule);
                      }}
                    />
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    <IconButton
                      onClick={() => {
                        const schedule = JSON.parse(
                          stringified,
                        ) as ScheduleType;
                        schedule[index] = [
                          ...schedule[index].slice(0, index2),
                          ...schedule[index].slice(1 + index2),
                        ];
                        setSchedule(schedule);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            <IconButton
              onClick={() => {
                const schedule = JSON.parse(stringified) as ScheduleType;
                schedule[index].push({ from: midDay, to: midDay });
                setSchedule(schedule);
              }}
            >
              <PlusOne />
            </IconButton>
          </CardContent>
        </Card>
      ))}
      <Button
        variant="contained"
        color="primary"
        disabled={getBase === stringified}
        onClick={() =>
          changeSchedule({
            variables: { schedule: stringified },
          })
        }
      >
        Sauvegarder
      </Button>
    </>
  );
};
