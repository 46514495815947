import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useDeleteUserMutation } from "../Generated/graphql";
import { useData } from "../Provider/Data";

interface Props {
  userId: number;
  onClose: () => void;
  onSuccess: () => void;
}

export const DeleteUser: React.FC<Props> = ({ userId, onClose, onSuccess }) => {
  const { users } = useData();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [deleteMutation, { loading }] = useDeleteUserMutation({
    variables: { id: userId },
    onCompleted: onSuccess,
  });

  return (
    <Dialog fullScreen={fullScreen} open onClose={onClose} maxWidth="md">
      <DialogContent>
        <Typography color="textSecondary" variant="h4" gutterBottom>
          {`Supprimer l'utilisateur ${
            users.find((user) => user.id === userId)?.email
          }`}
        </Typography>
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button onClick={onClose} color="secondary" disabled={loading}>
          Annuler
        </Button>
        <Button
          onClick={() => deleteMutation()}
          color="primary"
          disabled={loading}
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
