import React from "react";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import {
  yesterday,
  nextMonth,
  fullDay,
  setTime,
  humanReadableDate,
} from "@westcut/lib-common-tools";
import { MyDatePicker } from "@westcut/lib-client-common";
import {
  useAppointmentsLazyQuery,
  useCancelAppointmentMutation,
} from "../Generated/graphql";
import { CreateAppointment } from "../Components/CreateAppointment";

type TableType = {
  id: number;
  from: Date;
  name?: string;
  phone?: string;
  email?: string;
};

export const Appointments: React.FC = () => {
  const [getCreateModal, setCreateModal] = React.useState<boolean>(false);
  const [getMinFrom, setMinFrom] = React.useState<Date>(yesterday);
  const [getMaxFrom, setMaxFrom] = React.useState<Date>(nextMonth);
  const [loadData, { data }] = useAppointmentsLazyQuery();

  React.useEffect(() => {
    loadData({
      variables: {
        minFrom: setTime(getMinFrom, 0),
        maxFrom: setTime(getMaxFrom, fullDay),
      },
    });
  }, [loadData, getMinFrom, getMaxFrom]);

  const onCreationSuccess = () => {
    loadData({
      variables: {
        minFrom: setTime(getMinFrom, 0),
        maxFrom: setTime(getMaxFrom, fullDay),
      },
    });
    setCreateModal(false);
  };

  const [cancel] = useCancelAppointmentMutation({
    onCompleted: onCreationSuccess,
  });

  const appointments = data?.appointments;
  if (!appointments) return <></>;

  const processedAppointments: TableType[] = appointments.map(
    ({ id, from, userName, user, operator }) => ({
      id,
      from: new Date(from),
      name: userName || user?.name || undefined,
      phone: user?.phone || undefined,
      email: user?.email || undefined,
      operator: operator.name,
    }),
  );

  return (
    <>
      <Grid container justify="space-between">
        <MyDatePicker
          value={getMinFrom}
          onChange={setMinFrom}
          label="De"
          disablePast={false}
        />
        <MyDatePicker
          value={getMaxFrom}
          onChange={setMaxFrom}
          label="À"
          disablePast={false}
        />
      </Grid>
      <MaterialTable
        title=" "
        columns={[
          {
            title: "Date",
            field: "from",
            type: "datetime",
            render: (data) => <span>{humanReadableDate(data.from)}</span>,
          },
          { title: "Nom", field: "name" },
          { title: "Tel", field: "phone" },
          { title: "Email", field: "email" },
          { title: "Coiffeur", field: "operator" },
        ]}
        options={{
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 50, 100],
          actionsColumnIndex: -1,
        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Chercher",
          },
          header: {
            actions: "Supprimer",
          },
          pagination: {
            labelRowsSelect: "rdv",
            labelDisplayedRows: "{from}-{to} sur {count}",
          },
        }}
        actions={[
          {
            icon: "delete",
            tooltip: "Annuler le rdv",
            onClick: (_, rowData) =>
              cancel({ variables: { id: (rowData as TableType).id } }),
          },
          {
            icon: "add",
            tooltip: "Add User",
            isFreeAction: true,
            onClick: () => setCreateModal(true),
          },
        ]}
        data={processedAppointments}
      />
      {getCreateModal && (
        <CreateAppointment
          onClose={() => setCreateModal(false)}
          onSuccess={onCreationSuccess}
        />
      )}
    </>
  );
};
