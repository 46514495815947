import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useUserPayAppointmentMutation } from "../Generated/graphql";
import { useData } from "../Provider/Data";

interface Props {
  userId: number;
  onClose: () => void;
  onSuccess: () => void;
}

export const CreatePayment: React.FC<Props> = ({
  userId,
  onClose,
  onSuccess,
}) => {
  const { users } = useData();
  const theme = useTheme();
  const [getAmount, setAmount] = React.useState<number>(0);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [pay, { loading }] = useUserPayAppointmentMutation({
    variables: { id: userId, amount: getAmount },
    onCompleted: onSuccess,
  });
  return (
    <Dialog fullScreen={fullScreen} open onClose={onClose} maxWidth="md">
      <DialogContent>
        <Typography color="textSecondary" variant="h4" gutterBottom>
          {`Effectuer un paiement ${
            users.find((user) => user.id === userId)?.email
          }`}
        </Typography>
        <TextField
          type="number"
          value={getAmount / 100}
          onChange={(value) =>
            setAmount(Math.floor(parseFloat(value.target.value) * 100))
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">CHF</InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button onClick={onClose} color="secondary" disabled={loading}>
          Annuler
        </Button>
        <Button onClick={() => pay()} color="primary" disabled={loading}>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
