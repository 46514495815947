import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { humanReadableTime } from "@westcut/lib-common-tools";
import { formatPrice } from "@westcut/lib-client-common";
import {
  AppointmentFragment,
  usePayAppointmentMutation,
  useUserPayAppointmentMutation,
  useCancelAppointmentMutation,
} from "../Generated/graphql";

interface Props {
  appointment: AppointmentFragment;
  active: boolean;
  close: () => void;
  setActive: () => void;
  onUpdate: () => void;
}

export const AppointmentCard: React.FC<Props> = ({
  appointment,
  active,
  close,
  setActive,
  onUpdate,
}) => {
  const [pay] = usePayAppointmentMutation({ onCompleted: onUpdate });
  const [userPay] = useUserPayAppointmentMutation({ onCompleted: onUpdate });
  const [cancel] = useCancelAppointmentMutation({ onCompleted: onUpdate });

  const user = appointment.user;
  const name = appointment.userName || user?.name || user?.email;
  const phone = user?.phone ? ` - ${user.phone}` : "";

  const title = `${name} - ${appointment.appointmentType.name} - ${appointment.operator.name}`;
  const subheader = `${humanReadableTime(new Date(appointment.from))}${phone}`;

  const unitPrice = appointment.paid ? 0 : appointment.appointmentType.price;
  const fullPrice = user ? -user.balance : 0;

  return (
    <Grid item xs={12} md={6}>
      <Card>
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            active ? (
              <IconButton onClick={close}>
                <Close />
              </IconButton>
            ) : undefined
          }
        />
        {active ? (
          <CardActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => cancel({ variables: { id: appointment.id } })}
            >
              Annuler
            </Button>
            {fullPrice !== unitPrice && fullPrice !== 0 && (
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "auto" }}
                onClick={() => {
                  if (user) {
                    userPay({ variables: { id: user.id, amount: fullPrice } });
                  } else {
                    pay({ variables: { id: appointment.id } });
                  }
                }}
              >
                Payer compte {formatPrice(fullPrice)}
              </Button>
            )}
            {unitPrice !== 0 ? (
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "auto" }}
                onClick={() => {
                  pay({ variables: { id: appointment.id } });
                }}
              >
                Payer {formatPrice(unitPrice)}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "auto" }}
                disabled
              >
                Rdv payé
              </Button>
            )}
          </CardActions>
        ) : (
          <CardActions>
            {unitPrice > 0 && (
              <Typography variant="body1" color="secondary">
                À payer: {formatPrice(unitPrice)}
              </Typography>
            )}
            <Button
              variant="outlined"
              color="primary"
              style={{ marginLeft: "auto" }}
              onClick={setActive}
            >
              Activer
            </Button>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};
