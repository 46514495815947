import { makeStyles } from "@material-ui/core";
import { leftPadding, topPadding } from "@westcut/lib-client-common";

export const useMainStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: topPadding,
    height: "100vh",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: leftPadding,
    },
  },
  centeredWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: topPadding,
    height: "100vh",
  },
  content: {
    padding: 18,
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

export const useTopBarStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: topPadding,
  },
  img: {
    height: topPadding,
  },
}));
