import React from "react";
import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { useTopBarStyles } from "../Styles/layout";
import { StyledLink } from "@westcut/lib-client-common";

interface Props {
  logoUrl: string;
  navToggle?: () => void;
}

export const TopBar: React.FC<Props> = ({ logoUrl, navToggle }) => {
  const classes = useTopBarStyles();
  return (
    <AppBar elevation={0}>
      <Toolbar className={classes.toolbar}>
        <StyledLink to="/">
          <img alt="Logo" src={logoUrl} className={classes.img} />
        </StyledLink>
        <IconButton color="inherit" onClick={navToggle}>
          <Menu />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
