import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Appointment = {
  __typename?: "Appointment";
  appointmentType: AppointmentType;
  canceled?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  from: Scalars["DateTime"];
  id: Scalars["Float"];
  operator: Operator;
  paid?: Maybe<Scalars["DateTime"]>;
  to: Scalars["DateTime"];
  user?: Maybe<User>;
  userName?: Maybe<Scalars["String"]>;
};

export type AppointmentType = {
  __typename?: "AppointmentType";
  appointments: Array<Appointment>;
  disabled: Scalars["Boolean"];
  duration: Scalars["Float"];
  id: Scalars["Float"];
  name: Scalars["String"];
  price: Scalars["Float"];
};

export type AppointmentTypeCreate = {
  duration: Scalars["Float"];
  name: Scalars["String"];
  price: Scalars["Float"];
};

export type Block = {
  __typename?: "Block";
  createdAt: Scalars["DateTime"];
  from: Scalars["DateTime"];
  id: Scalars["Float"];
  operator: Operator;
  to: Scalars["DateTime"];
};

export type BlockCreate = {
  from: Scalars["DateTime"];
  operatorId: Scalars["Float"];
  to: Scalars["DateTime"];
};

export type Mutation = {
  __typename?: "Mutation";
  appointmentCreate: Appointment;
  appointmentTypeCreate: AppointmentType;
  appointmentTypeSetDisable: AppointmentType;
  blockCreate: Block;
  blockRemove: Scalars["Boolean"];
  changeSchedule?: Maybe<Operator>;
  deleteUser: Array<User>;
  login: Scalars["String"];
  setCancelled: Appointment;
  setPaid: Appointment;
  setTrusted: User;
  userPay: User;
};

export type MutationAppointmentCreateArgs = {
  input: OpAppointmentCreate;
};

export type MutationAppointmentTypeCreateArgs = {
  input: AppointmentTypeCreate;
};

export type MutationAppointmentTypeSetDisableArgs = {
  disabled?: Maybe<Scalars["Boolean"]>;
  id: Scalars["Float"];
};

export type MutationBlockCreateArgs = {
  input: BlockCreate;
};

export type MutationBlockRemoveArgs = {
  id: Scalars["Float"];
};

export type MutationChangeScheduleArgs = {
  schedule: Scalars["String"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["Float"];
};

export type MutationLoginArgs = {
  input: OperatorAuth;
};

export type MutationSetCancelledArgs = {
  id: Scalars["Float"];
};

export type MutationSetPaidArgs = {
  id: Scalars["Float"];
};

export type MutationSetTrustedArgs = {
  trusted?: Maybe<Scalars["Boolean"]>;
  userId: Scalars["Float"];
};

export type MutationUserPayArgs = {
  amount: Scalars["Float"];
  id: Scalars["Float"];
};

export type OpAppointmentCreate = {
  appointmentTypeId: Scalars["Float"];
  from: Scalars["DateTime"];
  operatorId: Scalars["Float"];
  userId?: Maybe<Scalars["Float"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Operator = {
  __typename?: "Operator";
  appointments: Array<Appointment>;
  blocks: Array<Block>;
  id: Scalars["Float"];
  isAdmin: Scalars["Boolean"];
  name: Scalars["String"];
  schedule: Scalars["String"];
};

export type OperatorAuth = {
  name: Scalars["String"];
  password: Scalars["String"];
};

export type Payment = {
  __typename?: "Payment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Float"];
  user: User;
};

export type Query = {
  __typename?: "Query";
  appointments: Array<Appointment>;
  appointmentTypes: Array<AppointmentType>;
  blocks: Array<Block>;
  me?: Maybe<Operator>;
  operators: Array<Operator>;
  possibilities: Array<Scalars["DateTime"]>;
  possibilitiesWithServiceType: Array<Scalars["DateTime"]>;
  users: Array<User>;
};

export type QueryAppointmentsArgs = {
  maxFrom?: Maybe<Scalars["DateTime"]>;
  minFrom?: Maybe<Scalars["DateTime"]>;
  operatorId?: Maybe<Scalars["Float"]>;
};

export type QueryBlocksArgs = {
  operatorId?: Maybe<Scalars["Float"]>;
};

export type QueryPossibilitiesArgs = {
  day: Scalars["DateTime"];
  operatorId: Scalars["Float"];
};

export type QueryPossibilitiesWithServiceTypeArgs = {
  appointmentTypeId: Scalars["Float"];
  day: Scalars["DateTime"];
  operatorId: Scalars["Float"];
};

export type User = {
  __typename?: "User";
  appointments: Array<Appointment>;
  balance: Scalars["Float"];
  email: Scalars["String"];
  id: Scalars["Float"];
  name?: Maybe<Scalars["String"]>;
  payments: Array<Payment>;
  phone?: Maybe<Scalars["String"]>;
  trusted: Scalars["Boolean"];
};

export type AppointmentsQueryVariables = Exact<{
  minFrom?: Maybe<Scalars["DateTime"]>;
  maxFrom?: Maybe<Scalars["DateTime"]>;
}>;

export type AppointmentsQuery = (
  { __typename?: "Query" }
  & { appointments: Array<(
    { __typename?: "Appointment" }
    & AppointmentFragment
  )> }
);

export type CreateAppointmentMutationVariables = Exact<{
  input: OpAppointmentCreate;
}>;

export type CreateAppointmentMutation = (
  { __typename?: "Mutation" }
  & { appointmentCreate: (
    { __typename?: "Appointment" }
    & Pick<Appointment, "id">
  ) }
);

export type PayAppointmentMutationVariables = Exact<{
  id: Scalars["Float"];
}>;

export type PayAppointmentMutation = (
  { __typename?: "Mutation" }
  & { setPaid: (
    { __typename?: "Appointment" }
    & Pick<Appointment, "id">
  ) }
);

export type UserPayAppointmentMutationVariables = Exact<{
  id: Scalars["Float"];
  amount: Scalars["Float"];
}>;

export type UserPayAppointmentMutation = (
  { __typename?: "Mutation" }
  & { userPay: (
    { __typename?: "User" }
    & Pick<User, "id">
  ) }
);

export type CancelAppointmentMutationVariables = Exact<{
  id: Scalars["Float"];
}>;

export type CancelAppointmentMutation = (
  { __typename?: "Mutation" }
  & { setCancelled: (
    { __typename?: "Appointment" }
    & Pick<Appointment, "id">
  ) }
);

export type AppointmentTypeCreateMutationVariables = Exact<{
  input: AppointmentTypeCreate;
}>;

export type AppointmentTypeCreateMutation = (
  { __typename?: "Mutation" }
  & { appointmentTypeCreate: (
    { __typename?: "AppointmentType" }
    & AppointmentTypeFragment
  ) }
);

export type AppointmentTypesQueryVariables = Exact<{ [key: string]: never; }>;

export type AppointmentTypesQuery = (
  { __typename?: "Query" }
  & { appointmentTypes: Array<(
    { __typename?: "AppointmentType" }
    & AppointmentTypeFragment
  )> }
);

export type BlockCreateMutationVariables = Exact<{
  input: BlockCreate;
}>;

export type BlockCreateMutation = (
  { __typename?: "Mutation" }
  & { blockCreate: (
    { __typename?: "Block" }
    & Pick<Block, "id">
  ) }
);

export type BlockRemoveMutationVariables = Exact<{
  id: Scalars["Float"];
}>;

export type BlockRemoveMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "blockRemove">
);

export type BlocksQueryVariables = Exact<{ [key: string]: never; }>;

export type BlocksQuery = (
  { __typename?: "Query" }
  & { blocks: Array<(
    { __typename?: "Block" }
    & BlockFragment
  )> }
);

export type GlobalDataQueryVariables = Exact<{ [key: string]: never; }>;

export type GlobalDataQuery = (
  { __typename?: "Query" }
  & { me?: Maybe<(
    { __typename?: "Operator" }
    & OperatorFragment
  )>, operators: Array<(
    { __typename?: "Operator" }
    & OperatorFragment
  )>, appointmentTypes: Array<(
    { __typename?: "AppointmentType" }
    & AppointmentTypeFragment
  )>, users: Array<(
    { __typename?: "User" }
    & UserFragment
  )> }
);

export type SetTrustedMutationVariables = Exact<{
  userId: Scalars["Float"];
}>;

export type SetTrustedMutation = (
  { __typename?: "Mutation" }
  & { setTrusted: (
    { __typename?: "User" }
    & Pick<User, "id">
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars["Float"];
}>;

export type DeleteUserMutation = (
  { __typename?: "Mutation" }
  & { deleteUser: Array<(
    { __typename?: "User" }
    & UserFragment
  )> }
);

export type PossibilitiesQueryVariables = Exact<{
  day: Scalars["DateTime"];
  operatorId: Scalars["Float"];
}>;

export type PossibilitiesQuery = (
  { __typename?: "Query" }
  & Pick<Query, "possibilities">
);

export type PossibilitiesWithServiceTypeQueryVariables = Exact<{
  day: Scalars["DateTime"];
  operatorId: Scalars["Float"];
  appointmentTypeId: Scalars["Float"];
}>;

export type PossibilitiesWithServiceTypeQuery = (
  { __typename?: "Query" }
  & Pick<Query, "possibilitiesWithServiceType">
);

export type LoginMutationVariables = Exact<{
  input: OperatorAuth;
}>;

export type LoginMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "login">
);

export type ChangeScheduleMutationVariables = Exact<{
  schedule: Scalars["String"];
}>;

export type ChangeScheduleMutation = (
  { __typename?: "Mutation" }
  & { changeSchedule?: Maybe<(
    { __typename?: "Operator" }
    & OperatorFragment
  )> }
);

export type AppointmentTypeFragment = (
  { __typename?: "AppointmentType" }
  & Pick<AppointmentType, "id" | "name" | "price" | "disabled">
);

export type OperatorFragment = (
  { __typename?: "Operator" }
  & Pick<Operator, "id" | "name" | "isAdmin" | "schedule">
);

export type UserFragment = (
  { __typename?: "User" }
  & Pick<User, "id" | "email" | "phone" | "name" | "balance" | "trusted">
);

export type AppointmentFragment = (
  { __typename?: "Appointment" }
  & Pick<Appointment, "id" | "from" | "to" | "userName" | "createdAt" | "paid">
  & { operator: (
    { __typename?: "Operator" }
    & OperatorFragment
  ), user?: Maybe<(
    { __typename?: "User" }
    & UserFragment
  )>, appointmentType: (
    { __typename?: "AppointmentType" }
    & AppointmentTypeFragment
  ) }
);

export type BlockFragment = (
  { __typename?: "Block" }
  & Pick<Block, "id" | "from" | "to">
  & { operator: (
    { __typename?: "Operator" }
    & OperatorFragment
  ) }
);

export const OperatorFragmentDoc = gql`
    fragment Operator on Operator {
  id
  name
  isAdmin
  schedule
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
  phone
  name
  balance
  trusted
}
    `;
export const AppointmentTypeFragmentDoc = gql`
    fragment AppointmentType on AppointmentType {
  id
  name
  price
  disabled
}
    `;
export const AppointmentFragmentDoc = gql`
    fragment Appointment on Appointment {
  id
  from
  to
  userName
  createdAt
  paid
  operator {
    ...Operator
  }
  user {
    ...User
  }
  appointmentType {
    ...AppointmentType
  }
}
    ${OperatorFragmentDoc}
${UserFragmentDoc}
${AppointmentTypeFragmentDoc}`;
export const BlockFragmentDoc = gql`
    fragment Block on Block {
  id
  from
  to
  operator {
    ...Operator
  }
}
    ${OperatorFragmentDoc}`;
export const AppointmentsDocument = gql`
    query Appointments($minFrom: DateTime, $maxFrom: DateTime) {
  appointments(minFrom: $minFrom, maxFrom: $maxFrom) {
    ...Appointment
  }
}
    ${AppointmentFragmentDoc}`;

/**
 * __useAppointmentsQuery__
 *
 * To run a query within a React component, call `useAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsQuery({
 *   variables: {
 *      minFrom: // value for 'minFrom'
 *      maxFrom: // value for 'maxFrom'
 *   },
 * });
 */
export function useAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
        return Apollo.useQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, baseOptions);
      }
export function useAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
          return Apollo.useLazyQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, baseOptions);
        }
export type AppointmentsQueryHookResult = ReturnType<typeof useAppointmentsQuery>;
export type AppointmentsLazyQueryHookResult = ReturnType<typeof useAppointmentsLazyQuery>;
export type AppointmentsQueryResult = Apollo.QueryResult<AppointmentsQuery, AppointmentsQueryVariables>;
export const CreateAppointmentDocument = gql`
    mutation CreateAppointment($input: OpAppointmentCreate!) {
  appointmentCreate(input: $input) {
    id
  }
}
    `;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<CreateAppointmentMutation, CreateAppointmentMutationVariables>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>) {
        return Apollo.useMutation<CreateAppointmentMutation, CreateAppointmentMutationVariables>(CreateAppointmentDocument, baseOptions);
      }
export type CreateAppointmentMutationHookResult = ReturnType<typeof useCreateAppointmentMutation>;
export type CreateAppointmentMutationResult = Apollo.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>;
export const PayAppointmentDocument = gql`
    mutation PayAppointment($id: Float!) {
  setPaid(id: $id) {
    id
  }
}
    `;
export type PayAppointmentMutationFn = Apollo.MutationFunction<PayAppointmentMutation, PayAppointmentMutationVariables>;

/**
 * __usePayAppointmentMutation__
 *
 * To run a mutation, you first call `usePayAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payAppointmentMutation, { data, loading, error }] = usePayAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePayAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<PayAppointmentMutation, PayAppointmentMutationVariables>) {
        return Apollo.useMutation<PayAppointmentMutation, PayAppointmentMutationVariables>(PayAppointmentDocument, baseOptions);
      }
export type PayAppointmentMutationHookResult = ReturnType<typeof usePayAppointmentMutation>;
export type PayAppointmentMutationResult = Apollo.MutationResult<PayAppointmentMutation>;
export type PayAppointmentMutationOptions = Apollo.BaseMutationOptions<PayAppointmentMutation, PayAppointmentMutationVariables>;
export const UserPayAppointmentDocument = gql`
    mutation UserPayAppointment($id: Float!, $amount: Float!) {
  userPay(id: $id, amount: $amount) {
    id
  }
}
    `;
export type UserPayAppointmentMutationFn = Apollo.MutationFunction<UserPayAppointmentMutation, UserPayAppointmentMutationVariables>;

/**
 * __useUserPayAppointmentMutation__
 *
 * To run a mutation, you first call `useUserPayAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPayAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPayAppointmentMutation, { data, loading, error }] = useUserPayAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useUserPayAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UserPayAppointmentMutation, UserPayAppointmentMutationVariables>) {
        return Apollo.useMutation<UserPayAppointmentMutation, UserPayAppointmentMutationVariables>(UserPayAppointmentDocument, baseOptions);
      }
export type UserPayAppointmentMutationHookResult = ReturnType<typeof useUserPayAppointmentMutation>;
export type UserPayAppointmentMutationResult = Apollo.MutationResult<UserPayAppointmentMutation>;
export type UserPayAppointmentMutationOptions = Apollo.BaseMutationOptions<UserPayAppointmentMutation, UserPayAppointmentMutationVariables>;
export const CancelAppointmentDocument = gql`
    mutation CancelAppointment($id: Float!) {
  setCancelled(id: $id) {
    id
  }
}
    `;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<CancelAppointmentMutation, CancelAppointmentMutationVariables>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>) {
        return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(CancelAppointmentDocument, baseOptions);
      }
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>;
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>;
export const AppointmentTypeCreateDocument = gql`
    mutation AppointmentTypeCreate($input: AppointmentTypeCreate!) {
  appointmentTypeCreate(input: $input) {
    ...AppointmentType
  }
}
    ${AppointmentTypeFragmentDoc}`;
export type AppointmentTypeCreateMutationFn = Apollo.MutationFunction<AppointmentTypeCreateMutation, AppointmentTypeCreateMutationVariables>;

/**
 * __useAppointmentTypeCreateMutation__
 *
 * To run a mutation, you first call `useAppointmentTypeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppointmentTypeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appointmentTypeCreateMutation, { data, loading, error }] = useAppointmentTypeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppointmentTypeCreateMutation(baseOptions?: Apollo.MutationHookOptions<AppointmentTypeCreateMutation, AppointmentTypeCreateMutationVariables>) {
        return Apollo.useMutation<AppointmentTypeCreateMutation, AppointmentTypeCreateMutationVariables>(AppointmentTypeCreateDocument, baseOptions);
      }
export type AppointmentTypeCreateMutationHookResult = ReturnType<typeof useAppointmentTypeCreateMutation>;
export type AppointmentTypeCreateMutationResult = Apollo.MutationResult<AppointmentTypeCreateMutation>;
export type AppointmentTypeCreateMutationOptions = Apollo.BaseMutationOptions<AppointmentTypeCreateMutation, AppointmentTypeCreateMutationVariables>;
export const AppointmentTypesDocument = gql`
    query AppointmentTypes {
  appointmentTypes {
    ...AppointmentType
  }
}
    ${AppointmentTypeFragmentDoc}`;

/**
 * __useAppointmentTypesQuery__
 *
 * To run a query within a React component, call `useAppointmentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppointmentTypesQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentTypesQuery, AppointmentTypesQueryVariables>) {
        return Apollo.useQuery<AppointmentTypesQuery, AppointmentTypesQueryVariables>(AppointmentTypesDocument, baseOptions);
      }
export function useAppointmentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentTypesQuery, AppointmentTypesQueryVariables>) {
          return Apollo.useLazyQuery<AppointmentTypesQuery, AppointmentTypesQueryVariables>(AppointmentTypesDocument, baseOptions);
        }
export type AppointmentTypesQueryHookResult = ReturnType<typeof useAppointmentTypesQuery>;
export type AppointmentTypesLazyQueryHookResult = ReturnType<typeof useAppointmentTypesLazyQuery>;
export type AppointmentTypesQueryResult = Apollo.QueryResult<AppointmentTypesQuery, AppointmentTypesQueryVariables>;
export const BlockCreateDocument = gql`
    mutation BlockCreate($input: BlockCreate!) {
  blockCreate(input: $input) {
    id
  }
}
    `;
export type BlockCreateMutationFn = Apollo.MutationFunction<BlockCreateMutation, BlockCreateMutationVariables>;

/**
 * __useBlockCreateMutation__
 *
 * To run a mutation, you first call `useBlockCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockCreateMutation, { data, loading, error }] = useBlockCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockCreateMutation(baseOptions?: Apollo.MutationHookOptions<BlockCreateMutation, BlockCreateMutationVariables>) {
        return Apollo.useMutation<BlockCreateMutation, BlockCreateMutationVariables>(BlockCreateDocument, baseOptions);
      }
export type BlockCreateMutationHookResult = ReturnType<typeof useBlockCreateMutation>;
export type BlockCreateMutationResult = Apollo.MutationResult<BlockCreateMutation>;
export type BlockCreateMutationOptions = Apollo.BaseMutationOptions<BlockCreateMutation, BlockCreateMutationVariables>;
export const BlockRemoveDocument = gql`
    mutation BlockRemove($id: Float!) {
  blockRemove(id: $id)
}
    `;
export type BlockRemoveMutationFn = Apollo.MutationFunction<BlockRemoveMutation, BlockRemoveMutationVariables>;

/**
 * __useBlockRemoveMutation__
 *
 * To run a mutation, you first call `useBlockRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockRemoveMutation, { data, loading, error }] = useBlockRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlockRemoveMutation(baseOptions?: Apollo.MutationHookOptions<BlockRemoveMutation, BlockRemoveMutationVariables>) {
        return Apollo.useMutation<BlockRemoveMutation, BlockRemoveMutationVariables>(BlockRemoveDocument, baseOptions);
      }
export type BlockRemoveMutationHookResult = ReturnType<typeof useBlockRemoveMutation>;
export type BlockRemoveMutationResult = Apollo.MutationResult<BlockRemoveMutation>;
export type BlockRemoveMutationOptions = Apollo.BaseMutationOptions<BlockRemoveMutation, BlockRemoveMutationVariables>;
export const BlocksDocument = gql`
    query Blocks {
  blocks {
    ...Block
  }
}
    ${BlockFragmentDoc}`;

/**
 * __useBlocksQuery__
 *
 * To run a query within a React component, call `useBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlocksQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlocksQuery(baseOptions?: Apollo.QueryHookOptions<BlocksQuery, BlocksQueryVariables>) {
        return Apollo.useQuery<BlocksQuery, BlocksQueryVariables>(BlocksDocument, baseOptions);
      }
export function useBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlocksQuery, BlocksQueryVariables>) {
          return Apollo.useLazyQuery<BlocksQuery, BlocksQueryVariables>(BlocksDocument, baseOptions);
        }
export type BlocksQueryHookResult = ReturnType<typeof useBlocksQuery>;
export type BlocksLazyQueryHookResult = ReturnType<typeof useBlocksLazyQuery>;
export type BlocksQueryResult = Apollo.QueryResult<BlocksQuery, BlocksQueryVariables>;
export const GlobalDataDocument = gql`
    query GlobalData {
  me {
    ...Operator
  }
  operators {
    ...Operator
  }
  appointmentTypes {
    ...AppointmentType
  }
  users {
    ...User
  }
}
    ${OperatorFragmentDoc}
${AppointmentTypeFragmentDoc}
${UserFragmentDoc}`;

/**
 * __useGlobalDataQuery__
 *
 * To run a query within a React component, call `useGlobalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalDataQuery(baseOptions?: Apollo.QueryHookOptions<GlobalDataQuery, GlobalDataQueryVariables>) {
        return Apollo.useQuery<GlobalDataQuery, GlobalDataQueryVariables>(GlobalDataDocument, baseOptions);
      }
export function useGlobalDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalDataQuery, GlobalDataQueryVariables>) {
          return Apollo.useLazyQuery<GlobalDataQuery, GlobalDataQueryVariables>(GlobalDataDocument, baseOptions);
        }
export type GlobalDataQueryHookResult = ReturnType<typeof useGlobalDataQuery>;
export type GlobalDataLazyQueryHookResult = ReturnType<typeof useGlobalDataLazyQuery>;
export type GlobalDataQueryResult = Apollo.QueryResult<GlobalDataQuery, GlobalDataQueryVariables>;
export const SetTrustedDocument = gql`
    mutation SetTrusted($userId: Float!) {
  setTrusted(userId: $userId) {
    id
  }
}
    `;
export type SetTrustedMutationFn = Apollo.MutationFunction<SetTrustedMutation, SetTrustedMutationVariables>;

/**
 * __useSetTrustedMutation__
 *
 * To run a mutation, you first call `useSetTrustedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTrustedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTrustedMutation, { data, loading, error }] = useSetTrustedMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetTrustedMutation(baseOptions?: Apollo.MutationHookOptions<SetTrustedMutation, SetTrustedMutationVariables>) {
        return Apollo.useMutation<SetTrustedMutation, SetTrustedMutationVariables>(SetTrustedDocument, baseOptions);
      }
export type SetTrustedMutationHookResult = ReturnType<typeof useSetTrustedMutation>;
export type SetTrustedMutationResult = Apollo.MutationResult<SetTrustedMutation>;
export type SetTrustedMutationOptions = Apollo.BaseMutationOptions<SetTrustedMutation, SetTrustedMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: Float!) {
  deleteUser(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const PossibilitiesDocument = gql`
    query Possibilities($day: DateTime!, $operatorId: Float!) {
  possibilities(day: $day, operatorId: $operatorId)
}
    `;

/**
 * __usePossibilitiesQuery__
 *
 * To run a query within a React component, call `usePossibilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePossibilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePossibilitiesQuery({
 *   variables: {
 *      day: // value for 'day'
 *      operatorId: // value for 'operatorId'
 *   },
 * });
 */
export function usePossibilitiesQuery(baseOptions?: Apollo.QueryHookOptions<PossibilitiesQuery, PossibilitiesQueryVariables>) {
        return Apollo.useQuery<PossibilitiesQuery, PossibilitiesQueryVariables>(PossibilitiesDocument, baseOptions);
      }
export function usePossibilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PossibilitiesQuery, PossibilitiesQueryVariables>) {
          return Apollo.useLazyQuery<PossibilitiesQuery, PossibilitiesQueryVariables>(PossibilitiesDocument, baseOptions);
        }
export type PossibilitiesQueryHookResult = ReturnType<typeof usePossibilitiesQuery>;
export type PossibilitiesLazyQueryHookResult = ReturnType<typeof usePossibilitiesLazyQuery>;
export type PossibilitiesQueryResult = Apollo.QueryResult<PossibilitiesQuery, PossibilitiesQueryVariables>;
export const PossibilitiesWithServiceTypeDocument = gql`
    query PossibilitiesWithServiceType($day: DateTime!, $operatorId: Float!, $appointmentTypeId: Float!) {
  possibilitiesWithServiceType(day: $day, operatorId: $operatorId, appointmentTypeId: $appointmentTypeId)
}
    `;

/**
 * __usePossibilitiesWithServiceTypeQuery__
 *
 * To run a query within a React component, call `usePossibilitiesWithServiceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePossibilitiesWithServiceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePossibilitiesWithServiceTypeQuery({
 *   variables: {
 *      day: // value for 'day'
 *      operatorId: // value for 'operatorId'
 *      appointmentTypeId: // value for 'appointmentTypeId'
 *   },
 * });
 */
export function usePossibilitiesWithServiceTypeQuery(baseOptions?: Apollo.QueryHookOptions<PossibilitiesWithServiceTypeQuery, PossibilitiesWithServiceTypeQueryVariables>) {
        return Apollo.useQuery<PossibilitiesWithServiceTypeQuery, PossibilitiesWithServiceTypeQueryVariables>(PossibilitiesWithServiceTypeDocument, baseOptions);
      }
export function usePossibilitiesWithServiceTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PossibilitiesWithServiceTypeQuery, PossibilitiesWithServiceTypeQueryVariables>) {
          return Apollo.useLazyQuery<PossibilitiesWithServiceTypeQuery, PossibilitiesWithServiceTypeQueryVariables>(PossibilitiesWithServiceTypeDocument, baseOptions);
        }
export type PossibilitiesWithServiceTypeQueryHookResult = ReturnType<typeof usePossibilitiesWithServiceTypeQuery>;
export type PossibilitiesWithServiceTypeLazyQueryHookResult = ReturnType<typeof usePossibilitiesWithServiceTypeLazyQuery>;
export type PossibilitiesWithServiceTypeQueryResult = Apollo.QueryResult<PossibilitiesWithServiceTypeQuery, PossibilitiesWithServiceTypeQueryVariables>;
export const LoginDocument = gql`
    mutation Login($input: OperatorAuth!) {
  login(input: $input)
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ChangeScheduleDocument = gql`
    mutation ChangeSchedule($schedule: String!) {
  changeSchedule(schedule: $schedule) {
    ...Operator
  }
}
    ${OperatorFragmentDoc}`;
export type ChangeScheduleMutationFn = Apollo.MutationFunction<ChangeScheduleMutation, ChangeScheduleMutationVariables>;

/**
 * __useChangeScheduleMutation__
 *
 * To run a mutation, you first call `useChangeScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeScheduleMutation, { data, loading, error }] = useChangeScheduleMutation({
 *   variables: {
 *      schedule: // value for 'schedule'
 *   },
 * });
 */
export function useChangeScheduleMutation(baseOptions?: Apollo.MutationHookOptions<ChangeScheduleMutation, ChangeScheduleMutationVariables>) {
        return Apollo.useMutation<ChangeScheduleMutation, ChangeScheduleMutationVariables>(ChangeScheduleDocument, baseOptions);
      }
export type ChangeScheduleMutationHookResult = ReturnType<typeof useChangeScheduleMutation>;
export type ChangeScheduleMutationResult = Apollo.MutationResult<ChangeScheduleMutation>;
export type ChangeScheduleMutationOptions = Apollo.BaseMutationOptions<ChangeScheduleMutation, ChangeScheduleMutationVariables>;