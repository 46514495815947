import React from "react";
import MaterialTable from "material-table";
import { formatPrice } from "@westcut/lib-client-common";
import { CreatePayment } from "../Components/CreatePayment";
import { useData } from "../Provider/Data";
import { useSetTrustedMutation } from "../Generated/graphql";
import { DeleteUser } from "../Components/DeleteUser";

type TableType = {
  id: number;
  email: string;
  phone?: string | null;
  name?: string | null;
  balance: number;
  trusted: boolean;
};

export const Accounts: React.FC = () => {
  const { users, refetch } = useData();
  const [setTrusted] = useSetTrustedMutation({ onCompleted: () => refetch() });
  const [getUserEdit, setUserEdit] = React.useState<number>();
  const [getUserDelete, setUserDelete] = React.useState<number>();

  React.useEffect(() => {
    refetch();
  }, [refetch]);
  const processedUsers: TableType[] = users.map(
    ({ id, email, phone, name, balance, trusted }) => ({
      id,
      email,
      phone,
      name,
      balance,
      trusted,
    }),
  );

  const onSuccess = () => {
    setUserEdit(undefined);
    setUserDelete(undefined);
    refetch();
  };
  return (
    <>
      <MaterialTable
        title=" "
        columns={[
          { title: "Id", field: "id" },
          { title: "Nom", field: "name" },
          { title: "Tel", field: "phone" },
          { title: "Email", field: "email" },
          {
            title: "Solde",
            field: "balance",
            render: (data) => (
              <span onClick={() => setUserEdit(data.id)}>
                {formatPrice(data.balance)}
              </span>
            ),
          },
        ]}
        options={{
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 50, 100],
          actionsColumnIndex: -1,
        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Chercher",
          },
          header: {
            actions: "Actions",
          },
          pagination: {
            labelRowsSelect: "utilisateur",
            labelDisplayedRows: "{from}-{to} sur {count}",
          },
        }}
        actions={[
          (rowData) => {
            return rowData.trusted
              ? {
                  icon: "delete",
                  tooltip: "Supprimer",
                  onClick: () => {
                    setUserDelete(rowData.id);
                  },
                }
              : {
                  icon: "done_outline",
                  tooltip: "De confiance",
                  onClick: () => {
                    setTrusted({
                      variables: { userId: rowData.id },
                    });
                  },
                };
          },
        ]}
        data={processedUsers}
      />

      {getUserEdit !== undefined && (
        <CreatePayment
          userId={getUserEdit}
          onClose={() => setUserEdit(undefined)}
          onSuccess={onSuccess}
        />
      )}
      {getUserDelete !== undefined && (
        <DeleteUser
          userId={getUserDelete}
          onClose={() => setUserDelete(undefined)}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};
