import { RouteDefinitionType } from "@westcut/lib-client-common";
import {
  Timelapse,
  Weekend,
  AccountCircle,
  Check,
  Pause,
  DateRange,
} from "@material-ui/icons";
import { Today } from "./Pages/Today";
import { Appointments } from "./Pages/Appointments";
import { Accounts } from "./Pages/Accounts";
import { UntrustedAccounts } from "./Pages/UntrustedAccounts";
import { Blocks } from "./Pages/Blocks";
import { Schedule } from "./Pages/Schedule";

export const routes: RouteDefinitionType[] = [
  {
    icon: Timelapse,
    mainComponent: Today,
    path: "/today",
    title: "Aujourd'hui",
  },
  {
    icon: Weekend,
    mainComponent: Appointments,
    path: "/appointments",
    title: "Rdvs",
  },
  {
    icon: AccountCircle,
    mainComponent: Accounts,
    path: "/accounts",
    title: "Comptes",
  },
  {
    icon: Check,
    mainComponent: UntrustedAccounts,
    path: "/untrusted_accounts",
    title: "Comptes non confirmé",
  },
  {
    icon: Pause,
    mainComponent: Blocks,
    path: "/blocks",
    title: "Pauses",
  },
  {
    icon: DateRange,
    mainComponent: Schedule,
    path: "/schedule",
    title: "Horaire",
  },
];
