import React from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Container,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useMaster } from "@westcut/lib-client-common";
import { OperatorAuth, useLoginMutation } from "../Generated/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export const Login: React.FC = () => {
  const classes = useStyles();
  const { setJwtPersistant } = useMaster();
  const [login, { loading }] = useLoginMutation({
    onCompleted(returnValue) {
      setJwtPersistant(returnValue?.login);
    },
  });

  const formik = useFormik<OperatorAuth>({
    initialValues: {
      name: "",
      password: "",
    },
    onSubmit: (input) => {
      login({ variables: { input } });
    },
  });

  return (
    <Box className={classes.root}>
      <Container maxWidth="sm">
        <form onSubmit={formik.handleSubmit}>
          <Box mt={3} mb={1}>
            <Typography align="center" color="textPrimary" variant="h2">
              Se connecter
            </Typography>
          </Box>
          <TextField
            id="name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            helperText={formik.errors.name}
            error={formik.errors.name !== undefined}
            label="Nom"
            type="string"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            id="password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            helperText={formik.errors.password}
            error={formik.errors.password !== undefined}
            label="Mot de passe"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Box my={2}>
            <Button
              color="primary"
              disabled={loading}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Se connecter
            </Button>
          </Box>
        </form>
      </Container>
    </Box>
  );
};
