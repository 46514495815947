import React, { createContext, useContext } from "react";
import {
  useGlobalDataQuery,
  OperatorFragment,
  AppointmentTypeFragment,
  UserFragment,
} from "../Generated/graphql";
import { useMaster } from "@westcut/lib-client-common";

type DataContextType = {
  operator: OperatorFragment;
  operators: OperatorFragment[];
  appointmentTypes: AppointmentTypeFragment[];
  users: UserFragment[];
  refetch: () => void;
};

const DataDefinition = createContext<DataContextType | undefined>(undefined);

export const useData = () => useContext(DataDefinition)!;

export const DataProvider: React.FC = ({ children }) => {
  const { getJwt, getErrors } = useMaster();
  const { data, refetch } = useGlobalDataQuery({
    skip: !getJwt || getErrors.length !== 0,
  });
  if (!data?.me) return <></>;
  return (
    <DataDefinition.Provider
      value={{
        operator: data.me,
        operators: data.operators,
        appointmentTypes: data.appointmentTypes,
        users: data.users,
        refetch,
      }}
    >
      {children}
    </DataDefinition.Provider>
  );
};
