import React from "react";
import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { fullDay, minuteRatio, setTime } from "@westcut/lib-common-tools";
import { MyDatePicker } from "@westcut/lib-client-common";
import { CreateAppointment } from "../Components/CreateAppointment";
import {
  AppointmentFragment,
  useAppointmentsLazyQuery,
} from "../Generated/graphql";
import { AppointmentCard } from "../Components/AppointmentCard";
import { useData } from "../Provider/Data";

export const Today: React.FC = () => {
  const { operator } = useData();
  const [loadData, { data }] = useAppointmentsLazyQuery();
  const [getMineOnly, setMineOnly] = React.useState<boolean>(true);
  const [getActive, setActive] = React.useState<number>();
  const [getCreateModal, setCreateModal] = React.useState<boolean>(false);
  const [getDate, setDate] = React.useState<Date>(new Date());

  React.useEffect(() => {
    loadData({
      variables: {
        minFrom: setTime(getDate, 0),
        maxFrom: setTime(getDate, fullDay),
      },
    });
  }, [loadData, getDate]);

  const onCreationSuccess = () => {
    loadData({
      variables: {
        minFrom: setTime(getDate, 0),
        maxFrom: setTime(getDate, fullDay),
      },
    });
    setCreateModal(false);
    setActive(undefined);
  };

  const isPaid = (appointment: AppointmentFragment): boolean =>
    appointment.paid || (appointment.user && appointment.user.balance >= 0);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyDatePicker value={getDate} onChange={setDate} />
          <FormControlLabel
            control={
              <Checkbox
                checked={getMineOnly}
                onChange={(value) => setMineOnly(value.target.checked)}
                color="primary"
              />
            }
            label="Filtrer"
          />
        </Grid>
        {(
          (getMineOnly
            ? data?.appointments.filter(
                (appointment) => appointment.operator.id === operator.id,
              )
            : data?.appointments) || []
        )
          .filter(
            (appointment) =>
              !isPaid(appointment) ||
              new Date().valueOf() - new Date(appointment.from).valueOf() <
                20 * minuteRatio,
          )
          .map((appointment, index) => (
            <AppointmentCard
              key={index}
              active={index === getActive}
              appointment={appointment}
              close={() => setActive(undefined)}
              setActive={() => setActive(index)}
              onUpdate={onCreationSuccess}
            />
          ))}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCreateModal(true)}
          >
            Nouveau
          </Button>
        </Grid>
      </Grid>
      {getCreateModal && (
        <CreateAppointment
          onClose={() => setCreateModal(false)}
          onSuccess={onCreationSuccess}
        />
      )}
    </>
  );
};
