import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { MyDatePicker, SingleSelect } from "@westcut/lib-client-common";
import { dayRatio, setTime } from "@westcut/lib-common-tools";
import { useBlockCreateMutation } from "../../Generated/graphql";
import { useData } from "../../Provider/Data";

interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

export const Day: React.FC<Props> = ({ onClose, onSuccess }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { operator, operators } = useData();
  const [getOperatorId, setOperatorId] = React.useState<number>(operator.id);
  const [getDay, setDay] = React.useState<Date>(new Date());
  const [create, { loading }] = useBlockCreateMutation();
  const processCreate = () => {
    create({
      variables: {
        input: {
          operatorId: getOperatorId,
          from: setTime(getDay, 0),
          to: setTime(getDay, dayRatio),
        },
      },
      update: () => {
        onSuccess();
      },
    });
  };
  return (
    <Dialog fullScreen={fullScreen} open onClose={onClose} maxWidth="md">
      <DialogContent>
        <Typography color="textSecondary" variant="h4" gutterBottom>
          Poser une pause
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <SingleSelect
              disableClearable
              currentValue={getOperatorId}
              label="Coiffeur"
              setNewValue={(value) => setOperatorId(value || operator.id)}
              options={operators.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MyDatePicker
              label="Jour"
              value={getDay}
              onChange={(value) => setDay(value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button onClick={onClose} color="secondary" disabled={loading}>
          Annuler
        </Button>
        <Button onClick={processCreate} color="primary" disabled={loading}>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
