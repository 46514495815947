import { humanReadableDate } from "@westcut/lib-common-tools";
import MaterialTable from "material-table";
import React from "react";
import { Custom } from "../Components/CreateBlock/Custom";
import { Day } from "../Components/CreateBlock/Day";
import { Period } from "../Components/CreateBlock/Period";
import { useBlockRemoveMutation, useBlocksQuery } from "../Generated/graphql";

type TableType = {
  id: number;
  from: Date;
  to: Date;
  operatorName: string;
};

enum ModalTypeEnum {
  NONE,
  CUSTOM,
  DAY,
  PERIOD,
}

export const Blocks: React.FC = () => {
  const { data, refetch } = useBlocksQuery();
  const [removeBlock] = useBlockRemoveMutation({
    onCompleted: () => refetch(),
  });
  const [getCreateModal, setCreateModal] = React.useState<ModalTypeEnum>(
    ModalTypeEnum.NONE,
  );

  const processedData: TableType[] = (data?.blocks || []).map(
    ({ id, from, to, operator }) => ({
      id,
      from: new Date(from),
      to: new Date(to),
      operatorName: operator.name,
    }),
  );

  const onCreationSuccess = () => {
    refetch();
    setCreateModal(ModalTypeEnum.NONE);
  };

  return (
    <>
      <MaterialTable
        title=" "
        columns={[
          { title: "Nom", field: "operatorName" },
          {
            title: "De",
            field: "from",
            type: "datetime",
            render: (data) => <span>{humanReadableDate(data.from)}</span>,
          },
          {
            title: "à",
            field: "to",
            type: "datetime",
            render: (data) => <span>{humanReadableDate(data.to)}</span>,
          },
        ]}
        options={{
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 50, 100],
          actionsColumnIndex: -1,
        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Chercher",
          },
          header: {
            actions: "Supprimer",
          },
          pagination: {
            labelRowsSelect: "pause",
            labelDisplayedRows: "{from}-{to} sur {count}",
          },
        }}
        actions={[
          {
            icon: "delete",
            tooltip: "Annuler la pause",
            onClick: (_, rowData) =>
              removeBlock({ variables: { id: (rowData as TableType).id } }),
          },
          {
            icon: "add",
            tooltip: "Ajouter une pause",
            isFreeAction: true,
            onClick: () => setCreateModal(ModalTypeEnum.CUSTOM),
          },
          {
            icon: "today",
            tooltip: "Ajouter un jour de pause",
            isFreeAction: true,
            onClick: () => setCreateModal(ModalTypeEnum.DAY),
          },
          {
            icon: "query_builder",
            tooltip: "Ajouter une période de pause",
            isFreeAction: true,
            onClick: () => setCreateModal(ModalTypeEnum.PERIOD),
          },
        ]}
        data={processedData}
      />
      {getCreateModal === ModalTypeEnum.CUSTOM && (
        <Custom
          onClose={() => setCreateModal(ModalTypeEnum.NONE)}
          onSuccess={onCreationSuccess}
        />
      )}
      {getCreateModal === ModalTypeEnum.DAY && (
        <Day
          onClose={() => setCreateModal(ModalTypeEnum.NONE)}
          onSuccess={onCreationSuccess}
        />
      )}
      {getCreateModal === ModalTypeEnum.PERIOD && (
        <Period
          onClose={() => setCreateModal(ModalTypeEnum.NONE)}
          onSuccess={onCreationSuccess}
        />
      )}
    </>
  );
};
