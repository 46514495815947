import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  MyDatePicker,
  SingleSelect,
  formatPrice,
} from "@westcut/lib-client-common";
import { humanReadableTime } from "@westcut/lib-common-tools";
import {
  useCreateAppointmentMutation,
  usePossibilitiesWithServiceTypeLazyQuery,
} from "../Generated/graphql";
import { useData } from "../Provider/Data";

interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

enum UserTypeEnum {
  NO_ACCOUNT,
  ACCOUNT,
}

export const CreateAppointment: React.FC<Props> = ({ onClose, onSuccess }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { operator, operators, appointmentTypes, users } = useData();
  const [getExistingUser, setExistingUser] = React.useState<UserTypeEnum>(
    UserTypeEnum.NO_ACCOUNT,
  );
  const [getUserName, setUserName] = React.useState<string>("");
  const [getUserId, setUserId] = React.useState<number>(users[0].id);
  const [getOperatorId, setOperatorId] = React.useState<number>(operator.id);
  const [getAppointmentTypeId, setAppointmentTypeId] = React.useState<number>(
    appointmentTypes[0].id,
  );
  const [getDay, setDay] = React.useState<Date>(new Date());
  const [getStart, setStart] = React.useState<Date | undefined>();

  const [
    loadData,
    { data, loading: LoadingPossibilities },
  ] = usePossibilitiesWithServiceTypeLazyQuery();
  const [create, { loading }] = useCreateAppointmentMutation();

  React.useEffect(() => {
    loadData({ variables: { day: getDay, operatorId: getOperatorId, appointmentTypeId: getAppointmentTypeId } });
  }, [loadData, getDay, getOperatorId, getAppointmentTypeId]);

  const slots =
    data?.possibilitiesWithServiceType.map((possibility) => new Date(possibility)) || [];

  const processCreate = () => {
    if (!getStart) {
      return;
    }
    create({
      variables: {
        input: {
          from: getStart,
          operatorId: getOperatorId,
          appointmentTypeId: getAppointmentTypeId,
          userName:
            getExistingUser === UserTypeEnum.NO_ACCOUNT
              ? getUserName
              : undefined,
          userId:
            getExistingUser === UserTypeEnum.ACCOUNT ? getUserId : undefined,
        },
      },
      update: () => {
        onSuccess();
      },
    });
  };
  return (
    <Dialog fullScreen={fullScreen} open onClose={onClose} maxWidth="md">
      <DialogContent>
        <Typography color="textSecondary" variant="h4" gutterBottom>
          Prendre un rendez-vous
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} style={{ marginBottom: 30 }}>
            <MyDatePicker value={getDay} onChange={setDay} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleSelect
              disableClearable
              currentValue={getOperatorId}
              label="Coiffeur"
              setNewValue={(value) => setOperatorId(value || operator.id)}
              options={operators.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleSelect
              disableClearable
              currentValue={getAppointmentTypeId}
              label="Service"
              setNewValue={(value) =>
                setAppointmentTypeId(value || appointmentTypes[0].id)
              }
              options={appointmentTypes.map(({ id, name, price }) => ({
                label: `${name} (${formatPrice(price)})`,
                value: id,
              }))}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {slots.length !== 0 ? (
              <SingleSelect
                disableClearable
                currentValue={getStart}
                label="Heure de début"
                setNewValue={(value) => setStart(value)}
                options={slots.map((slot) => ({
                  label: humanReadableTime(slot),
                  value: slot,
                }))}
              />
            ) : (
              <Typography color="textSecondary">
                {LoadingPossibilities
                  ? "Chargement"
                  : "Aucun horaire disponible pour ce jour"}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleSelect
              disableClearable
              currentValue={getExistingUser}
              label="Client"
              setNewValue={(value) =>
                setExistingUser(value || UserTypeEnum.NO_ACCOUNT)
              }
              options={[
                { label: "Avec compte", value: UserTypeEnum.ACCOUNT },
                { label: "Sans compte", value: UserTypeEnum.NO_ACCOUNT },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {getExistingUser === UserTypeEnum.ACCOUNT ? (
              <SingleSelect
                disableClearable
                enableFilter
                currentValue={getUserId}
                label="Utilisateur"
                setNewValue={(value) => setUserId(value || users[0].id)}
                options={users.map(({ id, name, email }) => ({
                  label: `${name ? name + " - " : ""}${email}`,
                  value: id,
                }))}
              />
            ) : (
              <TextField
                label="Nom"
                variant="outlined"
                fullWidth
                value={getUserName}
                onChange={(value) => setUserName(value.target.value)}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button onClick={onClose} color="secondary" disabled={loading}>
          Annuler
        </Button>
        <Button onClick={processCreate} color="primary" disabled={loading}>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
